import {AppBar, Button, Tab, Tabs, Tooltip} from "@mui/material"
import {Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {array, shape, string} from "prop-types"
import {useCallback, useEffect, useState} from "react"
import {
  FaEnvelope as Envelope,
  FaVolumeMute as MuteIcon,
  FaStreetView as TargetingIcon,
} from "react-icons/fa"
import {
  FiPieChart as AnalyticsIcon,
  FiCalendar as CalendarIcon,
  FiEdit as Edit,
  FiMap as Map,
  FiSettings as Settings,
  FiUpload as UploadIcon,
} from "react-icons/fi"
import {
  MdContentCopy as Copy,
  MdLightbulbOutline as LightbulbIcon,
  MdDesktopWindows as Monitor,
} from "react-icons/md"
import {connect} from "react-redux"
import {Link, Redirect, Route, Switch} from "react-router-dom"

import {isTemplateLimitReached} from "components/templates-list/templates-list.jsx"

import {fetchCurrentTeam} from "lib/api"
import useFeatures from "lib/hooks/use-features"

import {templateContentContextProvider} from "../../contexts/template-content-context"
import AccessControlled from "../access-control/access-controlled"
import AccessControlledRoute from "../access-control/access-controlled-route"
import AdapterLink from "../adapter-link/adapter-link"
import BadgeIfError from "../badge-if-error/badge-if-error"
import BatchUploader from "../batch-uploaders/batch-uploader"
import Box from "../box/box"
import CopyTextInput from "../copy-text-input/copy-text-input"
import Feature from "../feature/feature"
import CreateJourneyContainer from "../journeys/create-journey-container"
import Padded from "../padded/padded"
import TemplateAnalytics from "../template-analytics/template-analytics"
import MassMessagingContainer from "../template-content/mass-messaging/container"
import MassMessagingTemplateContent from "../template-content/mass-messaging/template-content"
import TemplateContent from "../template-content/template-content"
import TemplateInsights from "../template-insights/template-insights"
import TemplateJourneysContainer from "../template-journeys/template-journeys-container"
import TemplateManagementContainer from "../template-management/template-management-container"
import TemplateTargeting from "../template-targeting/template-targeting"
import TemplateContainer from "../templates/template-container"
import TitleBar from "../title-bar/title-bar"
import {templateContextProvider, useTemplateContext} from "./template-context"

const useStyles = makeStyles({
  tabWrapper: {
    padding: "9px 12px 6px",
  },
  tabIcon: {
    height: 50,
    marginRight: 12,
    marginBottom: "0 !important", // specificity necessary to override typical icon-on-top use case
  },
})

const validTabs = [
  "journeys",
  "management",
  "edit",
  "insights",
  "batch-upload",
  "targeting",
  "analytics",
  "content",
]

export const TemplateRouter = props => {
  const {template, templateId} = useTemplateContext()
  const [mayAddTemplates, setMayAddTemplates] = useState(undefined)
  const classes = useStyles()
  const {hasFeature} = useFeatures()

  useEffect(() => {
    fetchCurrentTeam({withOrg: true, withCountActiveTemplates: true}).then(team =>
      setMayAddTemplates(!isTemplateLimitReached(team))
    )
  }, [template])

  const getCurrentTab = () => {
    const urlParts = props.location.pathname.split("/")
    const currentUrlValue = urlParts[urlParts.length - 1]

    return validTabs.indexOf(currentUrlValue) > -1 ? currentUrlValue : "journeys"
  }

  const redirectToDefaultTab = useCallback(
    () =>
      props.currentUser && props.currentUser.permissions.includes("contacts:view") ? (
        <Redirect to={`${props.location.pathname}/journeys`} />
      ) : (
        <Redirect to={`${props.location.pathname}/management`} />
      ),
    [props]
  )

  const renderBatchUploader = useCallback(
    props => {
      if (template?.type === "tactical")
        return (
          <Box>
            <Typography>
              Batch uploads are not compatible with Tactical Campaigns, please use{" "}
              <Link to="targeting">targeting</Link> to enroll contacts into this campaign.
            </Typography>
          </Box>
        )
      else
        return (
          <BatchUploader
            {...props}
            template={template}
            templateId={templateId}
            isNotArchivedOrLive={
              template.status !== undefined && !["archived", "live"].includes(template.status)
            }
            isJourneyUploader
          />
        )
    },
    [template, templateId]
  )

  const renderDuplicateButton = ({disabled}) => {
    const encodedTemplateName = encodeURIComponent(template.name)
    return (
      <Button
        component={Link}
        color="grey"
        size="small"
        to={`/admin/templates/new?source_template_id=${templateId}&source_template_name=${encodedTemplateName}`}
        variant="contained"
        disabled={disabled}
      >
        <Copy /> Duplicate
      </Button>
    )
  }

  const wrapInTooltipIfArchived = (template, button) => {
    if (template.status === "archived") {
      const message = "This campaign is archived, new journeys cannot be created."
      return (
        <Tooltip title={message}>
          <span>{button}</span>
        </Tooltip>
      )
    } else {
      return button
    }
  }

  if (!template) return null

  const {match} = props
  const {path, url} = match

  return (
    <div data-testid="template-settings-router">
      <Switch>
        <Route path={`${path}/edit/pages/:pageSlug`}>
          <Redirect to={`${url}/edit`} />
        </Route>
        <AccessControlledRoute
          component={TemplateContainer}
          path={`${path}/edit/:pageSlug`}
          requiredPermissions={["templates:edit", "templates:view"]}
        />
        <AccessControlledRoute
          component={TemplateContainer}
          exact={true}
          path={`${path}/edit`}
          requiredPermissions={["templates:edit", "templates:view"]}
        />
        <AccessControlledRoute
          component={CreateJourneyContainer}
          path={`${path}/journeys/new`}
          requiredPermissions="contacts:create"
        />
        <Route path={path}>
          <TitleBar
            backButtonAttrs={{to: "/admin/templates", children: "Return to Campaigns Dashboard"}}
            title={
              <BadgeIfError
                BadgeIcon={MuteIcon}
                badgeSize={15}
                badgeText="This campaign has been muted."
                hasError={template.isMuted}
              >
                {template.name || ""}
              </BadgeIfError>
            }
          >
            {template.type !== "mass-messaging" && (
              <>
                <Button
                  component={Link}
                  color="grey"
                  size="small"
                  to={`/admin/templates/${templateId}/edit`}
                  variant="contained"
                >
                  <Edit /> Edit Content
                </Button>
                <Button
                  component={Link}
                  color="grey"
                  size="small"
                  target="_blank"
                  to={`/admin/templates/${templateId}/edit#preview`}
                  variant="contained"
                >
                  <Monitor /> Preview
                </Button>
                <AccessControlled requiredPermissions="templates:edit">
                  {mayAddTemplates ? (
                    renderDuplicateButton({disabled: false})
                  ) : (
                    <Tooltip title="You cannot create a campaign without exceeding your Active Campaign Limit. Please archive one of your existing campaigns before duplicating this campaign.">
                      <span>{renderDuplicateButton({disabled: true})}</span>
                    </Tooltip>
                  )}
                </AccessControlled>
              </>
            )}
            <AccessControlled requiredPermissions="contacts:create">
              {wrapInTooltipIfArchived(
                template,
                <Button
                  disabled={template.status === "archived"}
                  color="primary"
                  component={Link}
                  size="small"
                  to={`/admin/templates/${template.id}/journeys/new`}
                  variant="contained"
                >
                  <Envelope /> Send
                </Button>
              )}
            </AccessControlled>
          </TitleBar>

          <Padded>
            {template.type === "mass-messaging" ? (
              <MassMessagingContainer />
            ) : (
              <>
                <AppBar color="default" position="static">
                  <Tabs indicatorColor="secondary" textColor="inherit" value={getCurrentTab()}>
                    <AccessControlled requiredPermissions="contacts:view" value="journeys">
                      <Tab
                        classes={{root: classes.tabWrapper}}
                        component={AdapterLink}
                        icon={<Map className={classes.tabIcon} />}
                        iconPosition="start"
                        label="Journeys"
                        to={`/admin/templates/${templateId}/journeys`}
                      />
                    </AccessControlled>
                    <Feature emptyComponent={<div />} featureKey="atomic-assets" value={"content"}>
                      <AccessControlled requiredPermissions="templates:view">
                        <Tab
                          classes={{root: classes.tabWrapper}}
                          component={AdapterLink}
                          icon={
                            template.type === "mass-messaging" ? (
                              <CalendarIcon className={classes.tabIcon} />
                            ) : (
                              <Edit className={classes.tabIcon} />
                            )
                          }
                          iconPosition="start"
                          label={
                            template.type === "mass-messaging" ? "Content & Scheduling" : "Content"
                          }
                          to={`/admin/templates/${templateId}/content`}
                        />
                      </AccessControlled>
                    </Feature>
                    <AccessControlled requiredPermissions="insights:view" value="insights">
                      <Tab
                        classes={{root: classes.tabWrapper}}
                        component={AdapterLink}
                        icon={<LightbulbIcon className={classes.tabIcon} />}
                        iconPosition="start"
                        label="Insights"
                        to={`/admin/templates/${templateId}/insights`}
                      />
                    </AccessControlled>
                    <AccessControlled requiredPermissions="insights:view" value="analytics">
                      <Tab
                        classes={{root: classes.tabWrapper}}
                        component={AdapterLink}
                        icon={<AnalyticsIcon className={classes.tabIcon} />}
                        iconPosition="start"
                        label="Analytics"
                        to={`/admin/templates/${templateId}/analytics`}
                      />
                    </AccessControlled>
                    <AccessControlled requiredPermissions="contacts:create" value="batch-upload">
                      <Tab
                        classes={{root: classes.tabWrapper}}
                        component={AdapterLink}
                        icon={<UploadIcon className={classes.tabIcon} />}
                        iconPosition="start"
                        label="Batch Upload"
                        to={`/admin/templates/${templateId}/batch-upload`}
                      />
                    </AccessControlled>
                    {!hasFeature("atomic-assets") && (
                      <AccessControlled requiredPermissions="templates:edit" value="edit">
                        <Tab
                          classes={{root: classes.tabWrapper}}
                          component={AdapterLink}
                          icon={<Edit className={classes.tabIcon} />}
                          iconPosition="start"
                          label="Edit"
                          to={`/admin/templates/${templateId}/edit`}
                        />
                      </AccessControlled>
                    )}
                    <Feature featureKey="automated-targeting" value="targeting">
                      <AccessControlled requiredPermissions="targeting_conditions:edit">
                        <Tab
                          classes={{root: classes.tabWrapper}}
                          component={AdapterLink}
                          icon={<TargetingIcon className={classes.tabIcon} />}
                          iconPosition="start"
                          label="Targeting"
                          to={`/admin/templates/${templateId}/targeting`}
                        />
                      </AccessControlled>
                    </Feature>
                    <Tab
                      classes={{root: classes.tabWrapper}}
                      component={AdapterLink}
                      icon={<Settings className={classes.tabIcon} />}
                      iconPosition="start"
                      label="Management"
                      to={`/admin/templates/${templateId}/management`}
                      value="management"
                    />
                  </Tabs>
                </AppBar>

                <Switch>
                  <Route exact={true} path={`${path}/`} render={redirectToDefaultTab} />
                  <AccessControlledRoute
                    component={TemplateJourneysContainer}
                    path={`${path}/journeys`}
                    requiredPermissions="contacts:view"
                  />
                  <AccessControlledRoute
                    component={
                      template.type === "mass-messaging"
                        ? MassMessagingTemplateContent
                        : TemplateContent
                    }
                    path={`${path}/content`}
                    requiredPermissions="templates:view"
                  />
                  <AccessControlledRoute
                    component={TemplateInsights}
                    path={`${path}/insights`}
                    requiredPermissions="insights:view"
                  />
                  <AccessControlledRoute
                    component={TemplateAnalytics}
                    path={`${path}/analytics`}
                    requiredPermissions="insights:view"
                  />
                  <AccessControlledRoute
                    component={renderBatchUploader}
                    path={`${path}/batch-upload`}
                    requiredPermissions="contacts:create"
                  />
                  <AccessControlledRoute
                    component={TemplateTargeting}
                    path={`${path}/targeting`}
                    requiredPermissions="targeting_conditions:edit"
                  />
                  <Route component={TemplateManagementContainer} path={`${path}/management`} />
                </Switch>
              </>
            )}
          </Padded>

          <Padded>
            <Box>
              <CopyTextInput
                helperText="To reference this template in API requests, please use the ID provided above"
                label="Template ID"
                value={templateId}
              />
            </Box>
          </Padded>
        </Route>
      </Switch>
    </div>
  )
}

TemplateRouter.propTypes = {
  currentUser: shape({permissions: array}),
  location: shape({pathname: string.isRequired}),
  match: shape({path: string.isRequired}).isRequired,
}

export default connect(({session}) => ({currentUser: session.user}))(
  templateContextProvider(templateContentContextProvider(TemplateRouter))
)
