import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  Tooltip,
} from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {array, bool, func, object, shape, string} from "prop-types"
import {connect} from "react-redux"

import {useContentBlockEditor} from "components/content-block-editor/content-block-editor-context"
import DOSelect from "components/do-select/do-select"
import {journeyContext} from "components/journeys/journey-context"

import useFeatures from "lib/hooks/use-features"

const CtaLinkSettings = ({
  change,
  contentBlock,
  content_variables,
  field,
  showNoLinkOption,
  onSetContentVariable,
  template,
}) => {
  const isContentLibrary = !template?.id
  const {isEmail, setSelectedBlockDataChanges} = useContentBlockEditor()
  const {hasFeature} = useFeatures()

  const classes = useStyles()

  const handleChangeLinksTo = e => {
    const {
      currentTarget: {
        dataset: {name},
      },
    } = e

    change("linksTo", name)
  }

  const handleSelectPageSlug = e => {
    onSetContentVariable(e.target.value)
    setSelectedBlockDataChanges({dirty: true})
  }

  const linksTo = field("linksTo").value
  const {slug} = contentBlock

  return (
    <>
      <TextField
        autoFocus={true}
        fullWidth={true}
        helperText="Used for insights and analytics"
        id={`cta-${slug}-name`}
        label="Name"
        margin="normal"
        {...field("name")}
      />
      <FormControl className={classes.linkToGroup}>
        <FormLabel className={classes.linkToLabel}>Link to</FormLabel>
        <ButtonGroup size="small" variant="outlined">
          {showNoLinkOption && (
            <Tooltip title="This cta doesn't link anywhere.">
              <Button
                color={linksTo === "no-link" ? "primary" : "grey"}
                data-name="no-link"
                onClick={handleChangeLinksTo}
              >
                No Link
              </Button>
            </Tooltip>
          )}
          <Tooltip title="The cta will direct a recipient to a specific page in the journey.">
            <Button
              color={linksTo === "journey-page" ? "primary" : "grey"}
              data-name="journey-page"
              onClick={handleChangeLinksTo}
            >
              Journey Page
            </Button>
          </Tooltip>
          <Tooltip title="Use this option if you want to direct the recipient to a different place outside of the journey.">
            <Button
              color={linksTo === "url" ? "primary" : "grey"}
              data-name="url"
              data-testid="url-button"
              onClick={handleChangeLinksTo}
            >
              URL
            </Button>
          </Tooltip>
          <Tooltip title="Use this option for a custom action.">
            <Button
              color={linksTo === "personalized-url" ? "primary" : "grey"}
              data-name="personalized-url"
              data-testid="personalized-url-button"
              onClick={handleChangeLinksTo}
            >
              Personalized URL
            </Button>
          </Tooltip>
        </ButtonGroup>
      </FormControl>
      {linksTo === "journey-page" && isContentLibrary && (
        <div className={classes.journeyPagePanel}>
          {`You can choose a page to link to once this ${
            isEmail(contentBlock.containerId) ? "email" : "page"
          } gets added to a campaign.`}
        </div>
      )}
      {linksTo === "journey-page" && !isContentLibrary && template?.templatePages?.length > 0 && (
        <div className={cx({[classes.journeyPagePanel]: hasFeature("atomic-assets")})}>
          <FormControl fullWidth={true}>
            <InputLabel id={`cta-${slug}-page-slug`}>Page</InputLabel>
            <DOSelect
              labelId={`cta-${slug}-page-slug`}
              onChange={handleSelectPageSlug}
              value={content_variables?.[contentBlock.slug] ?? ""}
            >
              {template.templatePages.map(tp => (
                <MenuItem key={tp.page.slug} value={tp.page.slug}>
                  {tp.page.contentName}
                </MenuItem>
              ))}
            </DOSelect>
            {hasFeature("atomic-assets") && (
              <FormHelperText className={classes.formHelperText}>
                This only affects the campaign you are currently editing. Other campaigns that use
                this page will not be affected.
              </FormHelperText>
            )}
          </FormControl>
        </div>
      )}
      {(linksTo === "url" || linksTo === "personalized-url") && (
        <>
          {linksTo === "personalized-url" && (
            <TextField
              id={"metaPublicKey"}
              label="Meta Public Key"
              margin="normal"
              fullWidth
              {...field("metaPublicKey")}
            />
          )}
          <TextField
            id={`cta-${slug}-url`}
            label={linksTo === "personalized-url" ? "Default URL" : "URL"}
            margin="normal"
            fullWidth
            {...field("linkUrl")}
          />
        </>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  formHelperText: {
    lineHeight: 1.5,
  },
  journeyPagePanel: {
    alignItems: "center",
    background: theme.palette.background.default,
    boxShadow: "inset 0 8px 5px -5px #e4e4e6",
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    justifyContent: "center",
    marginTop: theme.spacing(0.5),
    minHeight: 100,
    padding: theme.spacing(1.5),

    // break out of parent's padding
    marginLeft: theme.spacing(-1.5),
    width: `calc(100% + ${theme.spacing(3)})`,
  },
  linkToGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  linkToLabel: {
    fontSize: "0.75rem",
    marginBottom: theme.spacing(1),
  },
}))

CtaLinkSettings.defaultProps = {
  showNoLinkOption: false,
}

CtaLinkSettings.propTypes = {
  change: func.isRequired,
  contentBlock: shape({
    containerId: string,
    slug: string,
  }).isRequired,
  content_variables: object,
  field: func.isRequired,
  onSetContentVariable: func.isRequired,
  showNoLinkOption: bool,
  template: shape({
    id: string,
    templatePages: array,
  }),
}

// WARNING: It seems JourneyContext has no provider for the sidebar, so calling it here is a no-op?
export default connect(({template}) => ({template}))(journeyContext(CtaLinkSettings))
