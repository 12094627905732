import {Badge, Tooltip} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object, string} from "prop-types"
import {useMemo} from "react"
import {FaExclamation} from "react-icons/fa"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"
import {useJourneyContext} from "components/journeys/journey-context"

import {useTemplateMessageContext} from "contexts/template-message-context"
import {useTemplatePageContext} from "contexts/template-page-context"
import {validDomainAndTld, validUrlOrMultipleUrls} from "lib/field-validations"
import {analytical} from "lib/hooks/use-analytics"

import BrandedButton from "../../../branded-button/branded-button"
import {useContentBlockEditor} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"
import {buttonColors} from "../helpers/button-colors"

// Decides whether to show the red `!` warning badge indicating that this CTA page link is invalid.
// Returns null if no errors, or the error message otherwise.
export const getCtaLinkErrorForBlock = ({
  type,
  contentBlock,
  content_variables,
  isEditMode,
  template,
}) => {
  const {
    slug,
    data: {linksTo, linkUrl},
  } = contentBlock
  const isContentLibrary = !template?.id
  const pageSlugExistsInTemplate = template?.templatePages?.find(
    ({page}) => page.newSlug === content_variables?.[slug]
  )

  if (
    isEditMode &&
    (linksTo === "url" || linksTo === "personalized-url") &&
    (linkUrl ?? "").trim() === ""
  ) {
    return `This ${type} links to a blank URL.`
  }

  // For CTAs that link to a page, if we're in the Content Library then we can't validate the link,
  // because the Content Library doesn't have a template scoped, so content_variables aren't available.
  if (isEditMode && !isContentLibrary && linksTo === "journey-page" && !pageSlugExistsInTemplate)
    return `This ${type} links to a journey page but one has not been selected yet.`

  return null
}

const CtaReadOnly = props => {
  const {isEditMode, isPreviewMode} = useContentBlockEditor()
  const {contact, onNavigateToPage, template} = useJourneyContext()
  const contactMetaPublic = useMemo(() => contact?.meta_public || {}, [contact])

  // Load content_variables from TemplatePage or TemplateMessage context (won't assume either is provided)
  const templatePageContextData = useTemplatePageContext()
  const templateMessageContextData = useTemplateMessageContext()
  const content_variables =
    templatePageContextData?.content_variables || templateMessageContextData?.content_variables

  const {contentBlock} = props
  const {
    data: {backgroundColor, metaPublicKey, iconSrc, linksTo, linkUrl, text},
  } = contentBlock

  const buttonProps = {color: "primary"}
  if (backgroundColor) buttonProps.style = buttonColors(contentBlock.data)

  const linkError = getCtaLinkErrorForBlock({
    type: "CTA",
    contentBlock: props.contentBlock,
    content_variables: content_variables,
    isEditMode: isEditMode,
    template: template,
  })

  const onClickLocal = e => {
    const {
      contentBlock: {
        contentContainerId,
        pageId,
        id: contentBlockId,
        data: {linksTo},
        slug,
      },
      track,
    } = props
    track("cta_clicked", {
      widgetSlug: slug,
      contentContainerId,
      contentBlockId,
      // We'll have to rethink this if we ever allow CTAs in footers
      pageId,
    })

    if (linksTo === "journey-page") {
      e.preventDefault()

      const pageSlug = content_variables?.[slug]
      const isPageInTemplate = !!template?.templatePages?.find(
        ({page}) => page.newSlug === pageSlug
      )

      if (pageSlug && isPageInTemplate)
        onNavigateToPage(isPreviewMode ? `${pageSlug}#preview` : pageSlug)
    }
  }
  const href = useMemo(
    () =>
      linksTo === "personalized-url" &&
      !validUrlOrMultipleUrls(contactMetaPublic[metaPublicKey]) &&
      !validDomainAndTld(contactMetaPublic[metaPublicKey])
        ? contactMetaPublic[metaPublicKey]
        : linkUrl,
    [contactMetaPublic, metaPublicKey, linksTo, linkUrl]
  )

  return (
    <div
      data-testid="cta-root"
      className={props.className}
      onClick={props.onClick}
      style={props.style}
    >
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{props.customCss}</style>
      <BrandedButton
        href={href}
        onClick={onClickLocal}
        target="_blank"
        variant="contained"
        {...buttonProps}
      >
        {text}
        {iconSrc && (
          <img alt="" aria-hidden="true" className="icon" crossOrigin="anonymous" src={iconSrc} />
        )}
      </BrandedButton>
      {linkError && (
        <Tooltip title={linkError}>
          <Badge badgeContent={<FaExclamation size={10} />} color="error" />
        </Tooltip>
      )}
    </div>
  )
}

CtaReadOnly.propTypes = {
  className: string,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  onClick: func,
  style: object,
  track: func.isRequired,
}

const styles = () => ({
  badge: {
    position: "absolute",
    top: 0,
  },
  container: {
    position: "relative",
  },
})

export default withStyles(styles)(analytical(CtaReadOnly))
