import {Typography} from "@mui/material"
import {bool, object, oneOf} from "prop-types"
import {FaCheck, FaTimes} from "react-icons/fa"

const StatusIndicator = ({classes, type, isValid}) => {
  const validityKey = isValid ? "valid" : "invalid"

  const label = {
    "audience-selected": {
      valid: "Audience Selected",
      invalid: "Audience Not Selected",
    },
    "no-personalization": {
      valid: "Template with no personalization",
      invalid: "Template has personalization",
    },
    "campaign-approved": {
      valid: "Campaign Approved",
      invalid: "Campaign Not Approved",
    },
  }

  return (
    <div className={classes.statusIndicatorParent}>
      {isValid ? (
        <FaCheck style={{color: "green", marginRight: "4px"}} />
      ) : (
        <FaTimes style={{color: "red", marginRight: "4px"}} />
      )}
      <Typography variant="body2" style={{color: isValid ? "green" : "red"}}>
        {label[type][validityKey]}
      </Typography>
    </div>
  )
}

StatusIndicator.propTypes = {
  classes: object,
  type: oneOf(["audience-selected", "no-personalization", "campaign-approved"]).isRequired,
  isValid: bool,
}

export default StatusIndicator
