import {Button, CircularProgress, Dialog, DialogContent, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {bool, object} from "prop-types"
import {useEffect, useState} from "react"
import {MdAdd as AddIcon} from "react-icons/md"
import {connect} from "react-redux"

import Box from "components/box/box"
import ConfirmDialog from "components/dialogs/confirm-dialog"
import DOTable from "components/table/table"
import TemplateMessageForm from "components/templates/template-message-form"

import {useTemplateContentContext} from "contexts/template-content-context"
import {headTemplateJourneys, updateTemplate} from "lib/api"

import {useTemplateContext} from "../../template-router/template-context"
import MessageAddDialog from "./message-add-dialog"
import ScheduledMessageRow from "./scheduled-message-row"
import StatusIndicator from "./status-indicator"

const TemplateContent = ({
  classes,
  currentUser,
  hasTemplateMessage,
  validAudience,
  validContent,
  validTemplate,
  validToSend,
}) => {
  const {
    template,
    templateId,
    editTemplateMessage,
    templateMessages,
    templateMessagesLoading,
    templateMessageToEdit,
    setTemplateMessageToEdit,
    removeTemplateMessage,
    completeEditTemplateMessage,
    makeMessageUnique,
    sendSampleMessage,
    fetchTemplateMessages,
    templatePages,
    setTemplateMessageToRemove,
    templateMessageToRemove,
    updateTemplateMessage,
    messageLoading,
  } = useTemplateContentContext()
  const {refreshTemplate} = useTemplateContext()

  const [messageSelectorOptions, setMessageSelectorOptions] = useState()
  const [totalJourneys, setTotalJourneys] = useState(0)

  const calcTotalJourneys = template => {
    headTemplateJourneys(template.id).then(([, fetchResponse]) => {
      const totalJourneys = parseInt(fetchResponse.headers.get("x-total-count"), 10)
      setTotalJourneys(totalJourneys)
    })
  }

  const handleSendNow = () => {
    updateTemplate(templateId, {status: "live"}).then(template => refreshTemplate(template.id))
  }

  useEffect(() => {
    calcTotalJourneys(template)
  }, [template, totalJourneys])

  useEffect(() => {
    fetchTemplateMessages()
  }, [fetchTemplateMessages])

  return (
    <>
      {messageLoading && (
        <Dialog open={true}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      )}
      <MessageAddDialog
        messageSelectorOptions={messageSelectorOptions}
        setMessageSelectorOptions={setMessageSelectorOptions}
      />
      <ConfirmDialog
        content="Are you sure you want to remove this message from this campaign?"
        onClose={() => setTemplateMessageToRemove(null)}
        onConfirm={removeTemplateMessage}
        open={!!templateMessageToRemove}
      />
      {templateMessageToEdit && (
        <TemplateMessageForm
          currentUser={currentUser}
          dialogTitle={templateMessageToEdit.contentName}
          onClose={() => setTemplateMessageToEdit(null)}
          onDelete={() => removeTemplateMessage(templateMessageToEdit.id)}
          onMakeMessageUnique={templateMessageToEdit.id ? makeMessageUnique : null}
          onSendSampleMessage={sendSampleMessage}
          onSubmit={completeEditTemplateMessage}
          templateId={templateId}
          templateMessage={templateMessageToEdit}
          templatePages={templatePages}
          type={templateMessageToEdit.message.type}
        />
      )}
      <Box component="section" data-testid="template-content">
        <Typography
          className={cx(classes.sectionHeader, classes.scheduledMessagesHeader)}
          gutterBottom={true}
          variant="h2"
        >
          Scheduled Messages
        </Typography>
        <div className={classes.tableWrapper}>
          <DOTable
            headers={[
              {field: "archived", label: "", sortable: false},
              {field: "scheduledAt", label: "Sent/Sending", sortable: false},
              {field: "status", label: "Status", sortable: false},
              {field: "preview", label: "", sortable: false},
              {field: "contentName", label: "Title", sortable: false},
              {field: "type", label: "Type", sortable: false},
              {field: "ineligibilityCondition", label: "Don't Send if", sortable: false},
              {field: "objective", label: "Objective", sortable: false},
              {field: "edit", label: "", sortable: false},
            ]}
            isTableLoading={templateMessagesLoading}
            noResults="No scheduled messages have been added to this campaign"
            paginationEnabled={false}
            rows={templateMessages}
          >
            {(row, index, column) => (
              <ScheduledMessageRow
                column={column}
                editTemplateMessage={editTemplateMessage}
                key={row.id}
                launchedAt={template.launchedAt}
                row={row}
                setTemplateMessageToRemove={setTemplateMessageToRemove}
                templatePages={templatePages}
                templateStatus={template.status}
                updateTemplateMessage={updateTemplateMessage}
              />
            )}
          </DOTable>
          {!hasTemplateMessage && (
            <div className={classes.tableActions}>
              <Button
                color="primary"
                onClick={() => setMessageSelectorOptions({type: "email", queue: "nurturing"})}
                variant="contained"
              >
                <AddIcon size={20} /> Add Scheduled Email
              </Button>
            </div>
          )}
          {template.status !== "live" && (
            <Box className={classes.tableActions} display="flex" alignItems="center" gap={2}>
              <StatusIndicator
                classes={classes}
                label="Audience selected"
                isValid={validAudience}
                type="audience-selected"
              />
              <StatusIndicator
                classes={classes}
                label="Template with no personalization"
                isValid={validContent}
                type="no-personalization"
              />
              <StatusIndicator
                classes={classes}
                label="Campaign approved"
                isValid={validTemplate}
                type="campaign-approved"
              />
              <Button
                color="primary"
                disabled={!validToSend}
                onClick={handleSendNow}
                variant="contained"
              >
                Send Now
              </Button>
            </Box>
          )}
        </div>
      </Box>
    </>
  )
}

TemplateContent.propTypes = {
  classes: object.isRequired,
  currentUser: object,
  hasTemplateMessage: bool,
  validAudience: bool,
  validContent: bool,
  validTemplate: bool,
  validToSend: bool,
}

const styles = theme => ({
  sectionHeader: {
    fontSize: theme.typography.fontSize * 2,
    marginBottom: theme.spacing(3),
  },
  scheduledMessagesHeader: {
    marginTop: theme.spacing(4),
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  hideWhenSorting: {
    "& .isSorting .showOnHover": {
      visibility: "hidden !important",
    },
  },
  sortingRow: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[8],
    borderBottom: "none",
    display: "flex",
    width: "100%",
    "& td": {
      display: "none",
    },
    "& > td:nth-child(1)": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      order: -2,
      justifySelf: "flex-start",
    },
    "& > td:nth-child(1) *": {
      visibility: "visible",
    },
    "& > td:nth-child(4)": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      flex: 1,
    },
  },
  statusIndicatorParent: {
    alignItems: "center",
    display: "flex",
    marginRight: "16px",
  },
  tableActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > button:first-child": {
      marginRight: theme.spacing(2),
    },
  },
  tableWrapper: {
    "& td:nth-child(2), & th:nth-child(2)": {
      paddingRight: theme.spacing(2),
    },
    "& td:nth-child(3), & th:nth-child(3)": {
      paddingRight: theme.spacing(2),
    },
    "& td:nth-child(4), & th:nth-child(4)": {
      paddingRight: 0,
    },
  },
})

const mapStateToProps = ({session}) => ({
  currentUser: session?.user,
})

export default withStyles(styles)(connect(mapStateToProps)(TemplateContent))
