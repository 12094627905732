import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {array, bool, func, object, string} from "prop-types"
import {useState} from "react"

import SaveButton from "components/save-button/save-button"
import {TemplateContext} from "components/template-router/template-context.js"

import {validEmail} from "lib/field-validations"
import {formify} from "lib/hooks/use-form"
import MaybeTooltip from "lib/maybe-tooltip"
import humanize from "lib/string/humanize"

import AccessControlled, {isUserPermitted} from "../../access-control/access-controlled"
import Box from "../../box/box"
import DangerButton from "../../danger-button/danger-button"
import UltraConfirmDialog from "../../dialogs/ultra-confirm-dialog"
import DocumentTitle from "../../document-title/document-title"
import Padded from "../../padded/padded"
import CampaignApprovalDialog from "../../template-management/campaign-approval-dialog"
import StatusIndicator from "./status-indicator"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  column: {
    width: "48%",
  },
  field: {
    margin: "10px 0",
  },
  sectionHeader: {
    fontSize: 22,
    letterSpacing: 0,
    fontWeight: "400",
  },
  sectionWrapper: {
    marginBottom: 30,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusIndicatorParent: {
    alignItems: "center",
    display: "flex",
    marginRight: "16px",
  },
  emergencyControls: {
    borderBottom: `2px solid ${theme.palette.error.main}`,
    padding: theme.spacing(1),
    marginBottom: 30,
    backgroundColor: theme.palette.error.light,
  },
  emergencyControlsHeader: {
    color: theme.palette.error.main,
  },
  sendApproval: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(2),
  },
  campaignUseCase: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  emergencyControlsLabel: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.error.main,
      borderRadius: "50%",
      padding: theme.spacing(0.5),
      fontSize: "1.5em",
      color: theme.palette.error.contrastText,
    },
  },
  controls: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
}))

const TemplateManagement = ({
  field = {},
  submitting,
  handleSubmit,
  template = {},
  currentUser,
  users = [],
  onDeleteTemplate,
  change,
}) => {
  const classes = useStyles()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showSendApprovalDialog, setShowSendApprovalDialog] = useState(false)
  const [showArchiveDialog, setShowArchiveDialog] = useState(false)

  const isPermittedToArchive = isUserPermitted(currentUser, "templates:archive")
  const isPermittedToEdit = isUserPermitted(currentUser, "templates:edit")

  return (
    <>
      <Box sx={{mb: 4}}>
        {template.name && <DocumentTitle title={`Campaigns - ${template.name} - Management`} />}
        <form onSubmit={handleSubmit}>
          <div className={classes.container}>
            <div className={classes.column}>
              <AccessControlled requiredPermissions="templates:edit">
                <div className={classes.sectionWrapper}>
                  <TextField
                    className={classes.field}
                    disabled={!isPermittedToEdit}
                    fullWidth={true}
                    label="Name"
                    {...field("name")}
                  />
                </div>
              </AccessControlled>
            </div>
            <div className={classes.column}>
              <div className={classes.sectionWrapper}>
                <Typography className={classes.sectionHeader} gutterBottom={true} variant="h2">
                  Sender Information
                </Typography>
                <Typography gutterBottom={true} variant="caption">
                  If any inputs below are left blank, then the team-level information will be used.
                </Typography>
                <AccessControlled requiredPermissions="templates:edit">
                  <div>
                    <TextField
                      className={classes.field}
                      disabled={!isPermittedToEdit}
                      fullWidth={true}
                      label="Sender Name"
                      {...field("emailSenderName")}
                    />
                    <TextField
                      className={classes.field}
                      disabled={!isPermittedToEdit}
                      fullWidth={true}
                      label="Sender Email"
                      {...field("emailSenderAddress")}
                    />
                  </div>
                </AccessControlled>
              </div>
            </div>
          </div>
          <div className={classes.controls}>
            <AccessControlled requiredPermissions="templates:delete">
              <DangerButton onClick={() => setShowDeleteDialog(true)}>Delete</DangerButton>
            </AccessControlled>
            {template.name && (
              <UltraConfirmDialog
                confirmationText={template.name}
                key={template.id}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={onDeleteTemplate}
                open={showDeleteDialog}
                recordType="template"
                renderDialogContent={confirmationText => (
                  <DialogContentText>
                    Deleting this template will also permanently delete all data associated with the
                    campaign including all data driving analytics and insights. We strongly
                    recommend archiving the campaign if any journeys were created. <br />
                    <br />
                    Deleting this template can't be undone. To confirm that you are ok with the
                    template and the associated data being deleted and still want to move forward
                    please type <b>{confirmationText}</b> in the box below.
                  </DialogContentText>
                )}
              />
            )}
            <AccessControlled requiredPermissions="templates:archive">
              <MaybeTooltip
                enterDelay={500}
                isTooltip={template.isArchived || false}
                title="Archived campaigns cannot be un-archived. To launch this campaign again, please duplicate the campaign."
              >
                <Button
                  color="grey"
                  onClick={() => setShowArchiveDialog(true)}
                  disabled={!isPermittedToArchive || template.isArchived || false}
                >
                  Archive
                </Button>
              </MaybeTooltip>
            </AccessControlled>
            <Dialog
              open={showArchiveDialog}
              onClose={() => setShowArchiveDialog(false)}
              aria-label={`Are you sure you want to archive this campaign?`}
              role="dialog"
            >
              <DialogTitle>Are you sure you want to archive this campaign?</DialogTitle>
              <DialogContent>
                <Typography>
                  Journeys are still visible to users after the campaign is archived, but no new
                  journeys can be created and no further messages will be sent. Archived campaigns
                  cannot be un-archived. If you need to launch this campaign after it has been
                  archived, you will need to duplicate the archived campaign.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button color="grey" onClick={() => setShowArchiveDialog(false)}>
                  Cancel
                </Button>
                <TemplateContext.Consumer>
                  {({refreshTemplate}) => (
                    <DangerButton
                      className="delete"
                      onClick={() => {
                        change("isArchived", true, {submitImmediately: true})
                        setShowArchiveDialog(false)

                        // Refresh the template stored in TemplateContext to make the "Duplicate" button
                        // available if the team was previously at the starter package template limit.
                        setTimeout(() => refreshTemplate(template.id), 1000)
                      }}
                    >
                      Yes, archive this campaign
                    </DangerButton>
                  )}
                </TemplateContext.Consumer>
              </DialogActions>
            </Dialog>
            <SaveButton submitting={submitting} />
          </div>
        </form>
      </Box>

      <Padded verticalOnly={true}>
        <Typography variant="h5">Campaign Approval</Typography>
        <Box>
          <div className={classes.sectionWrapper}>
            <div className={classes.row}>
              <div className={classes.sendApproval}>
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Current Status"
                  readOnly={true}
                  value={`${humanize(template.status)}`}
                />
                {["draft", "pending-approval", "not-approved"].includes(template.status) && (
                  <>
                    <Button
                      color="primary"
                      id="open-send-approvals-dialog"
                      variant="contained"
                      onClick={() => setShowSendApprovalDialog(true)}
                    >
                      {template.status === "draft" ? "Send for approval" : "Resend for approval"}
                    </Button>
                    <CampaignApprovalDialog
                      isOpen={showSendApprovalDialog}
                      onClose={() => setShowSendApprovalDialog(false)}
                      templateId={template.id}
                      users={users}
                    />
                  </>
                )}
              </div>
              <StatusIndicator
                classes={classes}
                type="campaign-approved"
                isValid={template.status === "approved" || template.status === "live"}
              />
            </div>
          </div>
        </Box>
      </Padded>
    </>
  )
}

TemplateManagement.propTypes = {
  template: object,
  currentUser: object,
  field: func,
  submitting: bool,
  handleSubmit: func,
  users: array,
  onDeleteTemplate: func,
  change: func,
  templateId: string,
}

// TODO: It would be nice to replace this formify HOC with a useForm hook so it's easier to reason
// about its inputs and outputs, but when I do so I get major text-editing bugs, for ex in the
// template title field. So leaving this as-is for now.

// Repeated comment from the original template-management but i think still valid
export default formify({
  enableReinitialize: true,
  validators: {
    emailSenderAddress: [validEmail],
    journeyDurationHours: [
      value =>
        parseInt(value, 10) < 1 ? "Journey Duration must be a positive amount of time" : null,
    ],
  },
})(TemplateManagement)
