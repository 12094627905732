import {Box, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {object} from "prop-types"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

// import BatchUploader from "components/batch-uploaders/batch-uploader"
import Padded from "components/padded/padded"
import TemplateTargeting from "components/template-targeting/template-targeting"
import {onDeleteTemplate, onUpdateTemplate} from "components/templates/template-actions"

import {fetchTemplateTargeting} from "lib/api"

import {useTemplateContentContext} from "../../../contexts/template-content-context"
// import AudienceSelect from "./audience-select"
import TemplateContent from "./template-content"
// import TemplateJourneys from "./template-journeys"
import TemplateManagement from "./template-management"

const styles = theme => ({})

const TemplateContainer = ({classes}) => {
  const currentUser = useSelector(state => state.session?.user)
  const users = useSelector(state => state.session?.teamUsers)
  const dispatch = useDispatch()
  const {template, templateId, templateMessages} = useTemplateContentContext()
  const handleSubmitTemplate = attrs => dispatch(onUpdateTemplate(templateId, attrs))
  const handleDeleteTemplate = () => dispatch(onDeleteTemplate(templateId))

  const hasTemplateMessage = templateMessages.length === 1
  const hasPersonalization = templateMessages[0]?.message?.hasPersonalization ?? false
  // const [activeAudienceModal, setActiveAudienceModal] = useState(null)
  const [validAudience, setValidAudience] = useState(false)
  const [validContent, setValidContent] = useState(false)
  const [validTemplate, setValidTemplate] = useState(false)
  const [validToSend, setValidToSend] = useState(false)

  // const onSelectAudience = audience => {
  //   if (audience === activeAudienceModal) return setActiveAudienceModal(null)
  //   setActiveAudienceModal(audience)
  // }

  const onSaveTargeting = () => {
    setValidAudience(true)
  }

  useEffect(() => {
    fetchTemplateTargeting(templateId).then(({targetingGroups, targetingImpact}) => {
      const hasTargetingGroups = targetingGroups.length > 0
      const validatedAudience = hasTargetingGroups && !targetingImpact
      setValidAudience(validatedAudience)
      const validatedContent = hasTemplateMessage && !hasPersonalization
      setValidContent(validatedContent)
      const validatedTemplate = template.status === "approved" || template.status === "live"
      setValidTemplate(validatedTemplate)
      setValidToSend(validatedAudience && validatedContent && validatedTemplate)
    })
  }, [hasPersonalization, hasTemplateMessage, template.status, templateId, templateMessages])

  // lots of commented out code here, rationale is that we are immediately following these changes with bringing batchUploader back in
  // this is merely to keep things as atomic as possible
  return (
    <Box>
      {/* {template.status !== "live" && (
        <Padded verticalOnly={true} className={classes.root}>
          <Typography variant="h5">Audience Select</Typography>
          <AudienceSelect
            classes={classes}
            onSelectAudience={onSelectAudience}
            validAudience={validAudience}
          />
        </Padded>
      )} */}

      {/* {activeAudienceModal === "batch" && (
        <Padded verticalOnly={true} className={classes.root}>
          <Typography variant="h5">Batch Uploader</Typography>
          <BatchUploader
            classes={classes}
            currentUser={currentUser}
            isJourneyUploader
            templateId={templateId}
          />
        </Padded>
      )} */}
      {/* {activeAudienceModal === "targeting" && ( */}
      <Padded verticalOnly={true} className={classes.root}>
        <Typography variant="h5">Template Targeting</Typography>
        <TemplateTargeting classes={classes} onSaveTargeting={onSaveTargeting} />
      </Padded>
      {/* )} */}
      <Padded verticalOnly={true} className={classes.root}>
        <Typography variant="h5">Template Management</Typography>
        <TemplateManagement
          classes={classes}
          currentUser={currentUser}
          initialValues={template}
          onSubmit={handleSubmitTemplate}
          onDeleteTemplate={handleDeleteTemplate}
          template={template}
          users={users}
        />
      </Padded>
      <Padded verticalOnly={true} className={classes.root}>
        <Typography variant="h5">Content & Scheduling</Typography>
        <TemplateContent
          classes={classes}
          currentUser={currentUser}
          hasTemplateMessage={hasTemplateMessage}
          validAudience={validAudience}
          validContent={validContent}
          validTemplate={validTemplate}
          validToSend={validToSend}
        />
      </Padded>
    </Box>
  )
}

TemplateContainer.propTypes = {
  classes: object.isRequired,
  currentUser: object,
}

export default withStyles(styles)(TemplateContainer)
